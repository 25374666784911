<template>
  <div class="switch-container" :class="{'checked':isChecked}">
    <label class="switch" :class="{right, '__loading':loading, 'disabled': disabled, 'no-label':noLabel, 'has-desc':hasDesc||!!description}">
      <input type="checkbox"
             v-bind="$attrs"
             :checked="val"
             @input="onInput($event)"
             :class="{ 'is-invalid': hasError }"
             @blur="isActive=false, wasActive=true"
             @focus="isActive=true">

      <div class="switch_checkbox"><span></span>
      <v-spinner v-if="loading" />
      </div>

      <template v-if="label">
        <span class="text_label">{{ label }}</span>

        <div v-if="info" class="info_icon" v-tippy="{interactive:interactive}" :content="info">
          <inline-svg :src="require('@/assets/img/info_icon.svg')"/>
        </div>

        <tippy v-else-if="$slots.info" :interactive="interactive">
          <template v-slot:trigger>
            <div class="info_icon">
              <inline-svg :src="require('@/assets/img/info_icon.svg')"/>
            </div>
          </template>
          <slot name="info"></slot>
        </tippy>
      </template>
	    <template v-else>
		    <span class="text_label" v-if="!noLabel">
			    <slot></slot>
		    </span>
	    </template>
    </label>
	  <p v-if="hasDesc" class="description">
		  <slot name="description"></slot>
	  </p>
	  <p v-else-if="!!description" class="description">
		  {{ description }}
	  </p>
    <p v-if="hasError" class="validation-message">
      {{ error }}
    </p>
  </div>

</template>

<script>
    import VSpinner from "@/components/base/v-spinner";
    export default {
	    components: {VSpinner},
      inheritAttrs: false,
      name: "VSwitch",
      // model: {
      //   prop: 'v-model',
      //   event: 'change'
      // },
      data: function (){
          return {
            wasActive: false,
            isActive: false,
          }
      },
        props: {
          info: {
            type: String,
            default: () => null,
          },
	        description: {
		        type: String,
		        default: () => null,
	        },
          error: {
              default: () => null
          },
	        interactive: {
		        type: Boolean,
		        default: () => false,
	        },
	        loading: {
		        type: Boolean,
		        default: () => false,
	        },
	        right: {
		        type: Boolean,
		        default: () => false,
	        },
	        disabled: {
		        type: Boolean,
		        default: () => false,
	        },
	        noLabel: {
		        type: Boolean,
		        default: () => false,
	        },
          "value": { default:false },
          // "modelValue": { default: "" },
          "label": { type: String, required: false},
          "trueValue": { default: true },
          "falseValue": { default: false }
        },

        computed: {
          val: {
            get() {
              return this.value === this.trueValue;
            },
            set(val) {
              this.$emit('input', val);
              this.$emit('change', val)
              return val
            }
          },
	        hasDesc() {
		        return !!this.$slots.description;
	        },
            hasError() {
                return !!this.error && this.wasActive;
            },
            isChecked() {
              if (this.val instanceof Array) {
                return this.val.includes(this.trueValue)
              }
              // Note that `true-value` and `false-value` are camelCase in the JS
              return this.val === this.trueValue
            }
        },
      methods: {
        onInput(ev) {
          let isChecked = ev.target.checked;
          this.val = isChecked ? this.trueValue : this.falseValue
        },
        updateInput(event) {
          return;
        	if (this.disabled) return; // do nothing.

          let isChecked = event.target.checked
          if (this.value instanceof Array) {
            let newValue = [...this.value]
            if (isChecked) {
              newValue.push(this.value)
            } else {
              newValue.splice(newValue.indexOf(this.value), 1)
            }
            this.$emit('change', newValue)
          } else {
            this.$emit('change', isChecked ? this.trueValue : this.falseValue)
          }
        }
      }
    };
</script>
<style lang="scss" scoped>
.switch-container {
	padding: 5px 6px;
	border-radius: 5px;
	height: fit-content;
	.description {
    font-weight: 400;
		margin: 2px 0 0 calc(40px + 10px);
		line-height: 1.4;
	}
	&:hover {
		background: $light-silver;
	}
	.switch {
		&.__loading {
			pointer-events: none;
			input:checked~.switch_checkbox {
				background: #d5dbf5;
			}
		}
		&.right {
			flex-direction: row-reverse;
			.text_label {
				margin-left: 5px;
				margin-right: 10px;
			}
		}
	}
}
</style>
<style lang="scss">
.switch-container {
  display: block;
  position: relative;
  margin: 10px 0;
}
.switch {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  .text_label {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #252A32;
    display: block;
    margin-left: 10px;
  }
	&.has-desc {
		.text_label {
			font-weight: 500;
		}
		&:hover {
			.text_label {
				text-decoration: $dark-grey underline dashed 1px;
				text-underline-offset: 5px;
			}
		}
	}
  input {
    display: none;
  }

  .switch_checkbox {
	  width: 40px;
	  min-width: 40px;
    height: 20px;
    position: relative;
    background: #E4E6F1;
    border-radius: 12px;
    transition: 0.15s all ease-in-out;
    margin-right: 0;

    span {
      display: block;
      width: 15px;
      height: 15px;
      background: #FFFFFF;
      //box-shadow: 0px 1px 1px rgba(37, 42, 50, 0.03);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 3px;
      border-radius: 50%;
      transition: 0.15s all ease-in-out;

	    box-shadow: inset 0 0 1px rgba(10, 10, 10, 0.55);
    }
  }

	&.no-label {
		.switch_checkbox {
			margin-right: 0;
		}
	}
	&.disabled {
		pointer-events: none;
		input:checked~.switch_checkbox {
			background: #87a3ff;
		}
		input:not(:checked)~.switch_checkbox:hover {
			background: #E4E6F1;
		}
	}
  input:checked~.switch_checkbox {
    background: #0e73e961;
  }

  input:not(:checked)~.switch_checkbox:hover {
    background: #D5E5FF;
  }

  input:checked~.switch_checkbox span {
	  right: 3px;
	  left: auto;
	  background: #0e73e9;
  }

}
</style>
